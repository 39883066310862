import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo, useState, type ReactNode } from 'react';
import BulkEditContext from './context';
import type { GridProduct } from '@odo/components/search/types';
import { dateObjectToIso } from '@odo/utils/date';
import type { UpdateProductInput } from '@odo/types/api-new';
import type { LoadingMessage } from '@odo/components/search/bulk-edit/types';
import {
  LoadingMessageStatus,
  type BulkEditChanges,
} from '@odo/components/search/bulk-edit/types';
import { mutationUpdateProduct } from '@odo/graphql/product-new';
import { success, error } from '@odo/utils/toast';

const BATCH_SIZE = 5;
const MAX_RETRIES = 3;

type QueueItem = () => Promise<void>;

const changesToUpdateProductInput = (
  deal: GridProduct,
  changes: BulkEditChanges
): UpdateProductInput => {
  // Overwrite daily shops if changes contain a daily shop
  const shops = changes.dailyShop
    ? [changes.dailyShop]
    : changes.removeAllShops
    ? []
    : deal.shops;

  const updateProductInput: UpdateProductInput = {
    isSampleReceived: deal.isSampleReceived,
    isPhotographedByStudio: deal.isPhotographedByStudio,

    status: changes.enabled,
    activeFromDate: changes.activeFromDate
      ? dateObjectToIso(new Date(`${changes.activeFromDate} 00:00`), true)
      : undefined,
    activeToDate: changes.activeToDate
      ? dateObjectToIso(new Date(`${changes.activeToDate} 23:59`), true)
      : undefined,

    categories: [
      ...shops,
      ...(deal.categories || []),
      ...(deal.permanentShops || []),
    ].reduce((acc, value) => {
      // NOTE: we need to convert the ID to a number and validate it
      const id = parseInt(value.id, 10);
      if (!isNaN(id)) {
        acc.push(id);
      }
      return acc;
    }, [] as number[]),
  };

  return updateProductInput;
};

const processUploadQueue = async (queue: QueueItem[]) => {
  await Promise.allSettled(queue.splice(0, BATCH_SIZE).map(task => task()));
  if (queue.length > 0) {
    await processUploadQueue(queue);
  }
};

const uploadDeal = async ({
  deal,
  retries = 0,
  changes,
  failedUploads,
  updateLoadingMessages,
  queue,
}: {
  deal: GridProduct;
  retries?: number;
  changes: BulkEditChanges;
  failedUploads: GridProduct[];
  updateLoadingMessages: (
    deal: GridProduct,
    status: LoadingMessageStatus,
    isRetryMessage?: boolean
  ) => void;
  queue: QueueItem[];
}) => {
  const input = changesToUpdateProductInput(deal, changes);
  updateLoadingMessages(deal, LoadingMessageStatus.loading, retries > 0);
  try {
    const response = await mutationUpdateProduct({
      id: deal.id,
      product: input,
    });
    if (response) {
      updateLoadingMessages(deal, LoadingMessageStatus.success);
    }
  } catch {
    if (retries < MAX_RETRIES) {
      // push the task back into the queue with one less available retry
      queue.push(() =>
        uploadDeal({
          deal,
          retries: retries + 1,
          changes,
          failedUploads,
          updateLoadingMessages,
          queue,
        })
      );
    } else {
      // if it fails all retries, add it to the failed uploads list
      failedUploads.push(deal);
      updateLoadingMessages(deal, LoadingMessageStatus.error);
    }
  }
};

const BulkEditContextProvider = ({ children }: { children: ReactNode }) => {
  const [deals, setDeals] = useState<GridProduct[]>([]);
  const [isBulkEditDialogOpen, setIsBulkEditDialogOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loadingInternal, setLoadingInternal] = useState(false);
  const [hasFailedUploads, setHasFailedUploads] = useState(false);

  // State for manually disabling the bulk edit button if API calls fail, or data is invalid
  const [disableBulkEdit, setDisableBulkEdit] = useState(false);

  // Callback to execute after bulk edit is complete, used to refresh data on search grid
  const [onBulkEditComplete, setOnBulkEditComplete] = useState<
    (() => void) | undefined
  >();

  const selectDeal = useCallback(
    (deal: GridProduct) => {
      if (!deals.map(d => d.id).includes(deal.id))
        setDeals(prevDeals => [...prevDeals, deal]);
    },
    [deals]
  );

  const removeDeal = (deal: GridProduct) => {
    setDeals(prevDeals => prevDeals.filter(d => d.id !== deal.id));
  };

  const clearSelection = () => setDeals([]);
  const openBulkEditDialog = () => setIsBulkEditDialogOpen(true);
  const closeBulkEditDialog = useCallback(() => {
    setIsBulkEditDialogOpen(false);
    if (!loadingInternal) {
      setSaving(false);
    }
  }, [loadingInternal]);

  const uploadChanges = useCallback(
    async (
      changes: BulkEditChanges,
      setLoadingMessages: Dispatch<
        SetStateAction<Record<string, LoadingMessage>>
      >
    ) => {
      setLoadingInternal(true);
      setSaving(true);
      setHasFailedUploads(false);
      setLoadingMessages({});

      const updateLoadingMessages = (
        deal: GridProduct,
        status: LoadingMessageStatus,
        isRetryMessage = false
      ) =>
        setLoadingMessages(prevMessages => ({
          ...prevMessages,
          [deal.id]: {
            productId: deal.id,
            label: isRetryMessage ? `${deal.name} (retrying...)` : deal.name,
            status,
          },
        }));

      const failedUploads: GridProduct[] = [];
      const queue: QueueItem[] = [];

      deals.forEach(deal => {
        queue.push(() =>
          uploadDeal({
            deal,
            changes,
            failedUploads,
            updateLoadingMessages,
            queue,
          })
        );
      });

      await processUploadQueue(queue);

      if (failedUploads.length === 0) {
        success('Bulk changes saved successfully, fetching fresh data...', {
          duration: 5000,
        });
        closeBulkEditDialog();
        setSaving(false);
        clearSelection();
      } else {
        error(
          'Some deals failed to save, please try again. Deals that were not saved are still selected.',
          { duration: 10000 }
        );
        setHasFailedUploads(true);
        setDeals(failedUploads);
      }
      setLoadingInternal(false);
      onBulkEditComplete && onBulkEditComplete();
    },
    [closeBulkEditDialog, deals, onBulkEditComplete]
  );

  const value = useMemo(
    () => ({
      deals,
      saving,
      selectDeal,
      removeDeal,
      clearSelection,
      isBulkEditDialogOpen,
      openBulkEditDialog,
      closeBulkEditDialog,
      disableBulkEdit,
      setDisableBulkEdit,
      setOnBulkEditComplete,
      uploadChanges,
      hasFailedUploads,
      numDeals: deals.length,
    }),
    [
      deals,
      saving,
      selectDeal,
      isBulkEditDialogOpen,
      closeBulkEditDialog,
      disableBulkEdit,
      uploadChanges,
      hasFailedUploads,
    ]
  );

  return (
    <BulkEditContext.Provider value={value}>
      {children}
    </BulkEditContext.Provider>
  );
};

export default BulkEditContextProvider;
